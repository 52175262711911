import { nextTick } from 'vue';
import { isString } from '@vue/shared';
import { isClient } from '@vueuse/core';
import { addClass, getStyle, removeClass } from '@shining3d/xl-element-plus/es/utils';
import { useZIndex } from '@shining3d/xl-element-plus/es/hooks';
import { createLoadingComponent } from './loading';
let fullscreenInstance;

export const Loading = ( options = {}) => {
	if ( !isClient ) { return undefined; }

	const resolved = resolveOptions( options );

	if ( resolved.fullscreen && fullscreenInstance ) {
		fullscreenInstance.remvoeElLoadingChild();
		fullscreenInstance.close();
	}

	const instance = createLoadingComponent({
		...resolved,
		closed: () => {
			resolved.closed?.();
			if ( resolved.fullscreen ) { fullscreenInstance = undefined; }
		}
	});

	addStyle( resolved, resolved.parent, instance );
	addClassList( resolved, resolved.parent, instance );

	resolved.parent.vLoadingAddClassList = () => addClassList( resolved, resolved.parent, instance );

	/**
   * add loading-number to parent.
   * because if a fullscreen loading is triggered when somewhere
   * a v-loading.body was triggered before and it's parent is
   * document.body which with a margin , the fullscreen loading's
   * destroySelf function will remove 'el-loading-parent--relative',
   * and then the position of v-loading.body will be error.
   */
	let loadingNumber =
    resolved.parent.getAttribute( 'loading-number' );
	if ( !loadingNumber ) {
		loadingNumber = '1';
	} else {
		loadingNumber = `${Number.parseInt( loadingNumber ) + 1}`;
	}
	resolved.parent.setAttribute( 'loading-number', loadingNumber );

	resolved.parent.appendChild( instance.$el );

	// after instance render, then modify visible to trigger transition
	nextTick(() => ( instance.visible.value = resolved.visible ));

	if ( resolved.fullscreen ) {
		fullscreenInstance = instance;
	}
	return instance;
};

const resolveOptions = ( options ) => {
	let target;
	if ( isString( options.target )) {
		target =
      document.querySelector < HTMLElement > ( options.target ) ?? document.body;
	} else {
		target = options.target || document.body;
	}
	return {
		isNew: options.isNew || false,
		secondaryText: options.secondaryText || '',
		parent: target === document.body || options.body ? document.body : target,
		background: options.background || '',
		svg: options.svg || '',
		svgViewBox: options.svgViewBox || '',
		spinner: options.spinner || false,
		text: options.text || '',
		fullscreen: target === document.body && ( options.fullscreen ?? true ),
		lock: options.lock ?? false,
		customClass: options.customClass || '',
		visible: options.visible ?? true,
		target
	};
};

const addStyle = async (
	options,
	parent,
	instance
) => {
	const { nextZIndex } = useZIndex();

	const maskStyle = {};
	if ( options.fullscreen ) {
		instance.originalPosition.value = getStyle( document.body, 'position' );
		instance.originalOverflow.value = getStyle( document.body, 'overflow' );
		maskStyle.zIndex = nextZIndex();
	} else if ( options.parent === document.body ) {
		instance.originalPosition.value = getStyle( document.body, 'position' );
		/**
     * await dom render when visible is true in init,
     * because some component's height maybe 0.
     * e.g. el-table.
     */
		await nextTick();
		for ( const property of ['top', 'left']) {
			const scroll = property === 'top' ? 'scrollTop' : 'scrollLeft';
			maskStyle[ property ] = `${
				( options.target ).getBoundingClientRect()[ property ] +
        document.body[ scroll ] +
        document.documentElement[ scroll ] -
        Number.parseInt( getStyle( document.body, `margin-${property}` ), 10 )
			}px`;
		}
		for ( const property of ['height', 'width']) {
			maskStyle[ property ] = `${
				( options.target ).getBoundingClientRect()[ property ]
			}px`;
		}
	} else {
		instance.originalPosition.value = getStyle( parent, 'position' );
	}
	for ( const [key, value] of Object.entries( maskStyle )) {
		instance.$el.style[ key ] = value;
	}
};

const addClassList = (
	options,
	parent,
	instance
) => {
	if (
		instance.originalPosition.value !== 'absolute' &&
    instance.originalPosition.value !== 'fixed'
	) {
		addClass( parent, 'el-loading-parent--relative' );
	} else {
		removeClass( parent, 'el-loading-parent--relative' );
	}
	if ( options.fullscreen && options.lock ) {
		addClass( parent, 'el-loading-parent--hidden' );
	} else {
		removeClass( parent, 'el-loading-parent--hidden' );
	}
};
