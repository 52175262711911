import {
	Transition,
	createApp,
	createVNode,
	h,
	reactive,
	ref,
	toRefs,
	vShow,
	withCtx,
	withDirectives
} from 'vue';
import { removeClass } from '@shining3d/xl-element-plus/es/utils';
import { $importImg } from '@/assets/common/common.js';

export function createLoadingComponent( options ) {
	let afterLeaveTimer;

	const afterLeaveFlag = ref( false );
	const data = reactive({
		...options,
		originalPosition: '',
		originalOverflow: '',
		visible: false
	});

	function setText( text ) {
		data.text = text;
	}

	function remvoeElLoadingChild() {
		vm.$el?.parentNode?.removeChild( vm.$el );
	}

	function destroySelf() {
		const target = data.parent;
		if ( !target.vLoadingAddClassList ) {
			let loadingNumber =
				target.getAttribute( 'loading-number' );
			loadingNumber = Number.parseInt( loadingNumber ) - 1;
			if ( !loadingNumber ) {
				removeClass( target, 'el-loading-parent--relative' );
				target.removeAttribute( 'loading-number' );
			} else {
				target.setAttribute( 'loading-number', loadingNumber.toString());
			}
			removeClass( target, 'el-loading-parent--hidden' );
		}
		remvoeElLoadingChild();
	}
	function close() {
		if ( options.beforeClose && !options.beforeClose()) { return; }

		const target = data.parent;
		target.vLoadingAddClassList = undefined;
		afterLeaveFlag.value = true;
		clearTimeout( afterLeaveTimer );

		afterLeaveTimer = window.setTimeout(() => {
			if ( afterLeaveFlag.value ) {
				afterLeaveFlag.value = false;
				destroySelf();
			}
		}, 400 );
		data.visible = false;

		options.closed?.();
	}

	function handleAfterLeave() {
		if ( !afterLeaveFlag.value ) { return; }
		afterLeaveFlag.value = false;
		destroySelf();
	}

	const elLoadingComponent = {
		name: 'ElLoading',
		setup() {
			return () => {
				// const svg = data.spinner || data.svg;
				const xlSpinner = h(
					'div',
					{ class: 'xl-circular' },
					[]
				);
				const originSpinner = h(
					'img',
					{
						src: $importImg( 'loading.png' ),
						class: 'circular'
					},
					[]
				);
				const spinner = data.isNew ? xlSpinner : originSpinner;

				const generateText = ( text ) => {
					if ( Array.isArray( text )) {
						return text.map(( item ) => {
							if ( typeof item === 'string' ) {
								return h( 'p', { class: 'el-loading-text' }, [item]);
							} else if (
								Object.prototype.toString.call( item ) === '[object Object]'
							) {
								const { content, ...props } = item || {};
								return h( 'p', { ...props }, [content]);
							}
							return h( 'p', { class: 'el-loading-text' }, [item]);
						});
					}
					return [h( 'p', { class: 'el-loading-text' }, [text])];
				};
				const spinnerText = data.text ? generateText( data.text ) : [undefined];
				if ( data.secondaryText ) {
					spinnerText.push( h( 'p', { class: 'el-secondary-text' }, [data.secondaryText]));
				}
				return h(
					Transition,
					{
						name: 'el-loading-fade',
						onAfterLeave: handleAfterLeave
					},
					{
						default: withCtx(() => [
							withDirectives(
								createVNode(
									'div',
									{
										style: { backgroundColor: data.background || '' },
										class: [
											'el-loading-mask',
											data.customClass,
											data.fullscreen ? 'is-fullscreen' : ''
										]
									},
									[
										h(
											'div',
											{ class: 'el-loading-spinner' },
											[spinner, ...spinnerText]
										)
									]
								),
								[[vShow, data.visible]]
							)
						])
					}
				);
			};
		}
	};

	const vm = createApp( elLoadingComponent ).mount( document.createElement( 'div' ));

	return {
		...toRefs( data ),
		setText,
		remvoeElLoadingChild,
		close,
		handleAfterLeave,
		vm,
		get $el() {
			return vm.$el;
		}
	};
}
