import http from '@/utils/http.js';
const newRootUrl = '/api';

const commonApi = {
	/**
	 * 获取国家码
	 * @returns
	 */
	getCountry() {
		return http.get( `${newRootUrl}/fetchCountries`, { errCode: 'commonApi.getCountry' });
	},
	/**
	 *
	 * @param {String} targetData
	 */
	getSettingList( targetData ) {
		return http.post( `${newRootUrl}/resourceListV2`, { data: { nameCode: targetData }, errCode: 'commonApi.getSettingList' });
	},
	/**
	 *
	 * @param {String} targetData
	 */
	settingsLang( params ) {
		return http.get( `${newRootUrl}/setUserLangV2?lang=${params.lang}`, { errCode: 'commonApi.settingsLang' });
	},
	/**
	 * 获取公告
	 * @returns
	 */
	getAnnouncement( params ) {
		return http.get( `${newRootUrl}/fetchAnnouncePushList`, { params, errCode: 'commonApi.getAnnouncement' });
	},
	/**
	 * 获取更新日志详情
	 * @returns
	 */
	getUpdateLogDetail( params ) {
		return http.get( `${newRootUrl}/getAnnouncePushInfo`, { params, errCode: 'commonApi.getUpdateLogDetail' });
	},
	/**
	 * 后端登出
	 */
	userLogout( data ) {
		return http.post( '/api/userLogout', { data, errCode: 'commonApi.userLogout' });
	}
};
export default commonApi;
