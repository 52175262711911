import $store from '@/store/index';

export const checkAuth = ( permCodes = []) => {
	const permissions = $store.getters[ 'space/permissions' ];
	return permissions.some(( item ) => permCodes.includes( item ));
};

export function formatDuration( seconds ) {
	const hours = Math.trunc( seconds / 3600 );
	const minutes = Math.trunc(( seconds % 3600 ) / 60 );
	const remainingSeconds = seconds % 60;
	return `${String( hours ).padStart( 2, '0' )}:${String( minutes ).padStart( 2, '0' )}:${String( remainingSeconds ).padStart( 2, '0' )}`;
}

export const setCursorToEnd = ( element ) => {
	if ( !element ) { return; }
	const range = document.createRange();
	const selection = window.getSelection();

	// 获取最后一个子节点
	const lastChild = element.lastChild;

	if ( lastChild ) {
	// 检查最后一个子节点是否为文本节点
		if ( lastChild.nodeType === Node.TEXT_NODE ) {
		// 如果是文本节点，将光标放在文本的末尾
			range.setStart( lastChild, lastChild.textContent.length );
		} else {
		// 如果最后一个子节点不是文本节点，将光标放在元素最后一个节点之后
			range.setStartAfter( lastChild );
		}
	} else {
	// 如果没有子节点，直接将光标放在元素内部开始处
		range.setStart( element, 0 );
	}

	range.collapse( true );
	selection.removeAllRanges();
	selection.addRange( range );
};

export function setCookie( name, value, days ) {
	let expires = '';
	if ( days ) {
		const date = new Date();
		date.setTime( date.getTime() + ( days * 24 * 60 * 60 * 1000 ));
		expires = '; expires=' + date.toUTCString();
	}
	document.cookie = name + '=' + ( value || '' ) + expires + '; path=/';
}
