import http from '@/utils/http.js';
const newRootUrl = '/api';

const api = {
	/**
	 * 软件端跳转过来查询模型是否转换完毕
	 * @returns
	 */
	softPreview( params ) {
		return http.get( `${newRootUrl}/softPreviewFile`, { params, errCode: 'space.getModelInfo' });
	},
	/**
	 * 获取云盘活动信息
	 * @returns
	 */
	fetchActivities( params ) {
		return http.get( `${newRootUrl}/fetchActivity`, { params, errCode: 'space.fetchActivities' });
	},
	/**
	 * 获取云盘空间列表
	 * @returns
	 */
	fetchSpaces( params ) {
		return http.get( `${newRootUrl}/fetchFrontSpace`, { params, errCode: 'space.fetchSpaces' });
	},
	/**
	 * 创建/编辑我的云盘空间
	 * @param {
	 * "comment": "", 简介
	 * "logo": {
	 *   bucket: '',
	 *   dfsID: ''
	 * }, 空间logo
	 * "name": "", 空间名称
	 * "qrCode": {
	 *   bucket: '',
	 *   dfsID: ''
	 * }, 空间二维码
	 * "spaceID": ""  空间ID
	 * } config
	 * @returns
	 */
	updateSpace( data ) {
		return http.post( `${newRootUrl}/changeSpace`, { data, errCode: 'space.updateSpace' }, { headers: { isCsrf: true } });
	},
	/**
	 * 获取空间类别信息(所有空间)
	 * @returns
	 */
	fetchSpaceCategories( params ) {
		return http.get( `${newRootUrl}/fetchAllCategory`, { params, errCode: 'space.fetchSpaceCategories' });
	},
	/**
	 * 获取空间标签信息
	 * @returns
	 */
	fetchSpaceTags( params ) {
		return http.get( `${newRootUrl}/fetchAllTag`, { params, errCode: 'space.fetchSpaceTags' });
	},
	/**
	 * 获取空间文件列表
	 * @returns
	 */
	fetchDirectories( data ) {
		return http.post( `${newRootUrl}/fetchDirectory`, { data, errCode: 'space.fetchDirectories' }, { headers: { isCsrf: true } });
	},
	/**
	 * 定向获取空间文件列表
	 * @returns
	 */
	fetchAppointedFiles( data ) {
		return http.post( `${newRootUrl}/fetchSpaceFileInfo`, { data, errCode: 'space.fetchAppointedFiles' }, { headers: { isCsrf: true } });
	},
	/**
	 * 获取空间使用信息
	 * @returns
	 */
	getSpaceCapacity( params ) {
		return http.get( `${newRootUrl}/getSpaceUsed`, { params, errCode: 'space.getSpaceUsed' });
	},
	/**
	 * 新建文件夹
	 * @returns
	 */
	createFolder( data ) {
		return http.post( `${newRootUrl}/createDirectoryFolder`, { data, errCode: 'space.createFolder' }, { headers: { isCsrf: true } });
	},
	/**
	 * 重命名文件
	 * @returns
	 */
	renameFile( data ) {
		return http.post( `${newRootUrl}/renameCloudSpaceFile`, { data, errCode: 'space.renameFile' }, { headers: { isCsrf: true } });
	},
	/**
	 * 移动文件
	 * @returns
	 */
	moveFiles( data ) {
		return http.post( `${newRootUrl}/moveCloudSpaceFile`, { data, errCode: 'space.moveFiles' }, { headers: { isCsrf: true } });
	},
	/**
	 * 提交云盘文件
	 * @returns
	 */
	commitFile( data ) {
		return http.post( `${newRootUrl}/uploadCloudSpaceFile`, { data, errCode: 'space.commitFile' }, { headers: { isCsrf: true } });
	},
	/**
	 * 下载文件
	 * @returns
	 */
	downloadFiles( data ) {
		return http.post( `${newRootUrl}/downloadCloudSpaceFileList`, { data, errCode: 'space.downloadFiles' }, { headers: { isCsrf: true } });
	},
	/**
	 * 删除文件
	 * @returns
	 */
	removeFiles( data ) {
		return http.post( `${newRootUrl}/removeCloudSpaceFile`, { data, errCode: 'space.removeFiles' }, { headers: { isCsrf: true } });
	},
	/**
	 * 分享文件
	 * @returns
	 */
	shareFile( data ) {
		return http.post( `${newRootUrl}/shareCloudSpaceFile`, { data, errCode: 'space.shareFile' }, { headers: { isCsrf: true } });
	},
	/**
	 * 获取文件夹目录
	 * @returns
	 */
	fetchDirectoryFolder( params ) {
		return http.get( `${newRootUrl}/fetchDirectoryFolder`, { params, errCode: 'space.fetchDirectoryFolder' });
	},
	/**
	 * 查看多模型信息
	 * @returns
	 */
	getModelPreInfos( params ) {
		return http.get( `${newRootUrl}/previewFiles`, { params, errCode: 'space.getModelInfo' });
	},
	/**
	 * 查看模型信息
	 * @returns
	 */
	getModelPreInfo( params ) {
		return http.get( `${newRootUrl}/previewFile`, { params, errCode: 'space.getModelInfo' });
	},
	/**
	 * 查看模型信息
	 * @returns
	 */
	getModelInfo( params ) {
		return http.get( `${newRootUrl}/viewFileDetail`, { params, errCode: 'space.getModelInfo' });
	},
	/**
	 * 修改模型信息
	 * @returns
	 */
	updateModelInfo( data ) {
		return http.post( `${newRootUrl}/changeCloudSpaceFile`, { data, errCode: 'space.updateModelInfo' }, { headers: { isCsrf: true } });
	},
	/**
	 * 获取文件(夹)信息
	 * @returns
	 */
	getFileInfo( params ) {
		return http.get( `${newRootUrl}/getCloudSpaceFile`, { params, errCode: 'space.getFileInfo' });
	},
	/**
	 * 获取最近打开/上传记录
	 * @returns
	 */
	fecthRecentFiles( params ) {
		return http.get( `${newRootUrl}/fetchRecentFileRecord`, { params, errCode: 'space.fecthRecentFiles' });
	},
	/**
	 * 获取分类文件
	 * @returns
	 */
	fecthCategoryFiles( data ) {
		return http.post( `${newRootUrl}/fetchCategoryFile`, { data, errCode: 'space.fecthCategoryFiles' }, { headers: { isCsrf: true } });
	},
	/**
	 * 公开模型
	 * @returns
	 */
	setupModelPubStatus( params ) {
		return http.get( `${newRootUrl}/setupModelPubStatus`, { params, errCode: 'space.setupModelPubStatus' });
	},
	/**
	 * 新增反馈
	 * @returns
	 */
	addFeedback( data ) {
		return http.post( `${newRootUrl}/addFeedback`, { data, errCode: 'space.addFeedback' }, { headers: { isCsrf: true } });
	},
	/**
	 * 下载接口（判断源文件还是gltf文件下载）
	 * @returns
	 */
	downloadCloudSpaceFile( data ) {
		return http.post( `${newRootUrl}/downloadCloudSpaceFile`, { data, errCode: 'space.downloadCloudSpaceFile' }, { headers: { isCsrf: true } });
	},
	/**
	 * 获取下载信息
	 * @returns
	 */
	getDownloadInfo( params ) {
		return http.get( `${newRootUrl}/getFileDownloadInfo`, { params, errCode: 'space.getDownloadInfo' });
	},
	/**
	 * 获得用户创建的空间节点分布情况
	 */
	fetchFrontSpaceDistribution( params ) {
		return http.get( `${newRootUrl}/fetchFrontSpaceDistribution`, { params });
	}
};
export default api;
