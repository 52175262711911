/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = { crossdetail: {}, unit: 'mm', bvhStatus: false, isFromTX3: false };
const mutations = {
	updateCrossdetail( state, crossdetail ) {
		state.crossdetail = { ...crossdetail };
	},
	updateModelUnit( state, unit ){
		state.unit = unit;
	},
	updateBvhStatus( state, bvhStatus ){
		state.bvhStatus = bvhStatus;
	},
	updateTX3Status( state, isFromTX3 ){
		state.isFromTX3 = isFromTX3;
	}
};

export default {
	namespaced: true,
	state,
	mutations
};
