import {
	createRouter,
	createWebHistory
} from 'vue-router';
import { VueCookieNext } from 'vue-cookie-next';
import { $logout, $exitBySearch } from '@/assets/common/common.js';
import pub from '@/views/pub/pub.js';
import info from '@/views/info/router.js';
import space from '@/views/space/router.js';
import pubLay from '@/views/pub/index.vue';
import _get from 'lodash/get';
import { useLang } from '@/hooks/lang.js';
const { $t } = useLang( '@shining3d/app' );

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import( '@/views/home.vue' ),
		redirect: '/space/home',
		children: [
			...info,
			...space
		]
	},
	{
		path: '/p',
		name: 'pubArea',
		component: pubLay,
		redirect: '/p/index',
		children: pub
	},
	{
		path: '/privacyPolicy',
		name: 'privacyPolicy',
		meta: { needAuth: false },
		component: () => import( '@/views/components/privacyPolicy.vue' )
	},
	{
		path: '/mobile',
		name: 'mobile',
		component: () => import( '@/views/space/mobile/index.vue' ),
		children: [
			{
				path: 'space/:space/model/:file',
				name: 'mobileSpaceModel',
				meta: { needAuth: false },
				component: () => import( '@/views/space/mobile/model/index.vue' )
			},
			{
				path: 'space/model/share',
				name: 'mobileSpaceModelShare',
				meta: { needAuth: false },
				component: () => import( '@/views/space/mobile/model/components/share.vue' )
			},
			{
				path: 'space/:space/index/:file?',
				name: 'mobileSpaceFile',
				meta: { needAuth: false },
				component: () => import( '@/views/space/mobile/file/index.vue' )
			}
		]
	},
	{
		path: '/space/file',
		name: 'spaceFile',
		meta: { needAuth: false },
		component: () => import( '@/views/space/redirect.vue' )
	},
	{
		path: '/space/modelShare',
		name: 'spaceModelShare',
		meta: { needAuth: false },
		component: () => import( '@/views/space/main/modelShare/index.vue' )
	}
];

const router = createRouter({
	history: createWebHistory( '/' ),
	routes
});

router.beforeEach(( to, from, next ) => {
	// 页面标题
	const title = _get( window, `app.i18n.@shining3d/app.title.${to.name}` );
	document.title = ( title ? title + '-' : '' ) + $t( 'header.projectName' );
	// 如果未匹配到路由跳转info
	if ( to.matched.length === 0 ) {
		if ( VueCookieNext.getCookie( 'token' )){
			from.name ? next({ name: from.name }) : next( '/info' );
		} else {
			$exitBySearch();
		}
	} else if ( !VueCookieNext.getCookie( 'token' ) && !to.name.includes( 'oemDomainPage' ) && to?.meta?.needAuth !== false ) {
		$logout();
	} else {
		next();
	}
});
export default router;
