import auth from './space/auth';
import { vLoading } from './loading/src/directive';
const directives = { auth, xlLoading: vLoading };
// 注册的一般写法，循环遍历directives，通过vue.directive注册
export default {
	install( Vue ){
		Object.keys( directives ).forEach(( key ) => {
			Vue.directive( key, directives[ key ]);
		});
	}
};
