export default [
	{
		path: '/space/home',
		name: 'spaceHome',
		component: () => import( './home.vue' )
	},
	{
		path: '/space/:space',
		name: 'space',
		component: () => import( './index.vue' ),
		children: [
			{
				path: 'index/:file?',
				name: 'spaceMain',
				showSide: true,
				meta: {
					svg: 'quanbuwenjian',
					needAuth: false
				},
				component: () => import( './main/index.vue' )
			},
			{
				path: 'model/:file',
				name: 'spaceModel',
				showSide: false,
				meta: {
					svg: 'quanbuwenjian',
					needAuth: false
				},
				component: () => import( './main/model/index.vue' )
			},

			{
				path: 'recent/open',
				name: 'spaceRecentOpen',
				showSide: true,
				meta: { svg: 'zuijindakai' },
				component: () => import( './main/recent.vue' )
			},
			{
				path: 'recent/upload',
				name: 'spaceRecentUpload',
				showSide: true,
				meta: { svg: 'zuijinshangchuan' },
				component: () => import( './main/recent.vue' )
			},
			{
				path: 'category',
				name: 'spaceCategory',
				showSide: true,
				meta: { svg: 'fenleichakan' },
				component: () => import( './main/category.vue' )
			},
			{
				path: 'share',
				name: 'spaceShare',
				showSide: true,
				meta: { svg: 'fenxiangjilu' },
				component: () => import( './main/share/index.vue' )
			},
			{
				path: 'setting/storage',
				name: 'spaceStorage',
				showSide: true,
				meta: { svg: 'chucungaikuang' },
				component: () => import( './setting/storage/index.vue' )
			},
			{
				path: 'setting/member',
				name: 'spaceMember',
				showSide: true,
				meta: { svg: 'chengyuanguanli' },
				component: () => import( './setting/member/index.vue' )
			},
			{
				path: 'setting/property',
				name: 'spaceProperty',
				showSide: true,
				meta: {
					svg: 'kongjianshuxing',
					permission: ['spaceEdit']
				},
				component: () => import( './setting/property/index.vue' )
			},
			{
				path: 'mall',
				name: 'spaceShop',
				showSide: false,
				meta: {
					needAuth: true,
					permission: ['spaceShop']
				},
				component: () => import( './setting/mall/index.vue' )
			}
		]
	}
];
