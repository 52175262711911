import { createStore } from 'vuex';
import { $getLocalStorage, $setLocalStorage } from '@/assets/common/common.js';
import infoStore from './info';
import router from '@/router';
import fileUpload from './modules/fileUpload';
import space from './modules/space';
import operate from './modules/operate';
import { VueCookieNext } from 'vue-cookie-next';
import _get from 'lodash/get';
import _merge from 'lodash/merge';

const store = _merge( infoStore, {
	state: {
		i18n: {}, // 语言
		langs: [
			{ value: 'zh-cn', label: '简体中文' },
			{ value: 'en-us', label: 'English' }
			// { value: 'ja-jp', label: '日本語' }
		],
		countryList: $getLocalStorage( 'countryList' ) || [], // 国家列表
		sysParams: $getLocalStorage( 'sysParamsNew' ) || [], // OEM信息
		uiColor: $getLocalStorage( 'uiColor' ) || {}, // OEM配色
		userInfo: $getLocalStorage( 'userInfo' ) || {}, // 用户信息
		permsList: $getLocalStorage( 'perms' ) || {}, // 用户权限点
		phoneAreaList: [], // 国家码列表
		global: {}, // ETCD配置（菜单、版本号等）
		isAside: true, // 侧边显示隐藏
		applyCancelOn: '',
		privacyPolicySwitch: false,
		termsOfUseSwitch: false,
		langSwitch: sessionStorage.getItem( 'langSwitch' ) !== 'false',
		nodeList: []
	},
	mutations: {
		setApplyCancelOn( state, val ) {
			state.applyCancelOn = val;
		},
		setPolicy( state, val ) {
			if ( val === 'privacyPolicy' ) {
				state.privacyPolicySwitch = !state.privacyPolicySwitch;
			} else if ( val === 'termsOfUse' ) {
				state.termsOfUseSwitch = !state.termsOfUseSwitch;
			}
		},
		setI18n( state, val ) {
			state.i18n = val;
		},
		setCountry( state, val ) {
			localStorage.setItem( 'countryList', JSON.stringify([...val]));
			state.countryList = val;
		},
		setSysParams( state, val ) {
			$setLocalStorage( 'sysParamsNew', val );
			// 如果OEM Logo图标存在则修改网页图标
			if ( document.querySelector( 'link[rel="icon"]' ) && _get( val, 'logo' )) {
				document.querySelector( 'link[rel="icon"]' ).href = _get( val, 'logo' );
			}
			// 更新网页标题OEM名称
			const routerName = _get( router, 'currentRoute.value.name' );
			const title = routerName ? _get( state, `i18n.@shining3d/app.title.${routerName}` ) : '';
			const oemTitle = _get( val, 'title' );
			document.title = title ? title + ( oemTitle ? '-' + oemTitle : '' ) : oemTitle;
			state.sysParams = val;
		},
		setUiColor( state, val ) {
			// 标题色
			val.logoColor && document.documentElement.style.setProperty( '--oem-title-color', val.logoColor );
			$setLocalStorage( 'uiColor', val );
			state.uiColor = val;
		},

		setPhone( state, val ) {
			localStorage.setItem( 'areaCodeOptions', JSON.stringify([...val]));
			state.phoneAreaList = val;
		},
		setUserInfo( state, val ) {
			// 标题存储
			if ( val.nickName ) {
				VueCookieNext.setCookie( 'uTitle', val.nickName, { expire: '3d', path: '/' });
			}
			$setLocalStorage( 'userInfo', val );
			state.userInfo = val;
		},
		setPermsList( state, val ) {
			$setLocalStorage( 'perms', val );
			state.permsList = val;
		},
		setIsAside( state, val ) {
			state.isAside = val;
		},
		setGlobal( state, val ) {
			state.global = val;
		},
		setLangSwitch( state, val ) {
			state.langSwitch = val;
			sessionStorage.setItem( 'langSwitch', val );
		},
		setNodeList( state, val ) {
			state.nodeList = val;
		}
	},
	actions: {},
	modules: {
		fileUpload,
		space,
		operate
	}
});

export default createStore( store );
