/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = {
	files: [],
	pendingIds: [], // 待删除队列，在请求接口加载完毕后触发本地删除，减少页面抖动
	trigger: false
};
const mutations = {
	addFiles( state, files ) {
		state.files = state.files.concat( files );
	},
	updateFiles( state, files ) {
		const uids = files.map(( file ) => file.uid );
		// state.files = state.files.filter(( file ) => !uids.includes( file.uid )).concat( files ); // 虽然简单，但会改变排序，前端显示扇动
		state.files = state.files.map(( file ) => uids.includes( file.uid ) ? files.find(( f ) => f.uid === file.uid ) : file );
	},
	deleteFiles( state, uids ) {
		state.files = state.files.filter(( file ) => !uids.includes( file.uid ));
		state.pendingIds = state.pendingIds.filter(( uid ) => !uids.includes( uid ));
	},
	changeTrigger( state, ids ) {
		state.trigger = !state.trigger;
		state.pendingIds = state.pendingIds.concat( ids || []);
	}
};

export default {
	namespaced: true,
	state,
	mutations
};
