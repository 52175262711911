import $moment from 'moment';
import commonApi from '@/api/common';
import { XlMessage, XlMessageBox } from '@shining3d/ui-components';
import info from '@/api/info';
import store from '../../store';
import { VueCookieNext } from 'vue-cookie-next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { nextTick } from 'vue';
import { useLang } from '@/hooks/lang.js';
import { privacyUpdate } from '@shining3d/xl-login';
import spaceApi from '@/api/space';

import router from '@/router';

const cc = VueCookieNext.getCookie( 'cc' ) || 'CN';

const { $t } = useLang( '@shining3d/app' );
// 地区日期差格式化转换
function $convertUTCDateToLocalDate( sd, format = 'YYYY-MM-DD HH:mm:ss' ) {
	if ( !sd || Object.keys( sd ).length < 1 ) {
		return '';
	}
	// TODO 时区差需要地区数据支撑
	let utcOffset = 0;
	const zones = store.state.countryList.find(( val ) => cc.toUpperCase() === val.id );
	if ( zones ) {
		try {
			utcOffset = JSON.parse( zones.timezones )[ 0 ].utcOffset;
		} catch ( error ) {
			console.log( error );
		}
	}
	return $moment.utc( sd ).utcOffset( utcOffset ).
		format( format );
}

// 图片引入
const $importImg = ( imgPath ) => new URL( `../image/${imgPath}`, import.meta.url ).href;

// 地区日期差格式化转换
function $convertLocalDateToUTCDate( sd, format = 'YYYY-MM-DD HH:mm:ss' ) {
	if ( !sd || Object.keys( sd ).length < 1 ) {
		return '';
	}
	// TODO 时区差需要地区数据支撑
	let utcOffset = 0;
	const zones = store.state.countryList.find(( val ) => cc.toUpperCase() === val.id );
	if ( zones ) {
		try {
			utcOffset = JSON.parse( zones.timezones )[ 0 ].utcOffset;
		} catch ( error ) {
			console.log( error );
		}
	}
	return $moment.utc( sd ).utcOffset( -utcOffset ).
		format( format );
}

// 获取国家码初始值
function $getPhoneArea( sd ) {
	const PhoneArea = store.state.countryList.find(( val ) => val.id === ( sd || cc ));
	return PhoneArea ? PhoneArea.phone : '86';
}

// 本地存储
function $setLocalStorage( type, val, prefix = 'dental-' ) {
	localStorage.setItem( prefix + type, JSON.stringify( val ));
}

// 获取本地存储
function $getLocalStorage( type, prefix = 'dental-' ) {
	if ( !type ) {
		return '';
	}
	let objtxt = localStorage.getItem( prefix + type );
	try {
		objtxt = JSON.parse( objtxt );
	} catch ( error ) {
		console.log( objtxt );
	}
	return objtxt;
}

// 删除本地存储
function $removeLocalStorage( type, prefix = 'dental-' ) {
	localStorage.removeItem( prefix + type );
	localStorage.removeItem( type );
}

// 获取个人信息
async function $getInfo() {
	const res = await info.getInfo();
	if ( res.status === 'success' ) {
		// 更新创建时间转换
		res.result.lastOn = $convertUTCDateToLocalDate( res.result.lastOn );
		res.result.createOn = $convertUTCDateToLocalDate( res.result.createOn );
		// 上次登录地址拼接
		const country = _get( res.result, 'lastRegion.country', '' );
		const city = _get( res.result, 'lastRegion.city', '' );
		const area = _get( res.result, 'lastRegion.area', '' );
		res.result.region = ( country === 'xx' ? ' ' : country ) + ' ' + ( area === 'xx' ? ' ' : area ) + ' ' + ( city === 'xx' ? ' ' : city );
		// 更新个人信息存储
		store.commit( 'setUserInfo', { ...res.result } || {});
		return { ...res.result };
	} else {
		return {};
	}
}

function $exitBySearch( redirectTo ) {
	VueCookieNext.removeCookie( 'token' );
	VueCookieNext.removeCookie( 'user_id' );
	VueCookieNext.removeCookie( 'uTitle' );
	$removeLocalStorage( 'perms' ); // 权限
	$removeLocalStorage( 'userInfo' ); // 用户信息
	$removeLocalStorage( 'factoryObjs' ); // 机构列表
	$removeLocalStorage( 'currentOrganization' ); // 选中机构
	$removeLocalStorage( 'defaultDentalOrderReceive' ); // 案例参数
	$removeLocalStorage( 'cancelPromptFlag' ); // 账户注销
	if ( !VueCookieNext.getCookie( 'token' )) {
		// 云平台处理，token存在跳过，入参路径>oem+当前页面路径
		window.location.href = redirectTo || router.resolve({ name: 'oemDomainPage', params: { oemDomain: 'index' }, query: { redirect: encodeURIComponent( window.location.pathname + window.location.search ) } }).href;
	} else {
		console.log( 'token未清除' );
	}
}

async function $getSettingList({ type, lang }) {
	const res = await commonApi.getSettingList( type );
	if ( res.status === 'success' && res.result && res.result.length > 0 ) {
		return res.result.map(( v ) => {
			const obj = {};
			obj.value = v.keyName;
			try {
				obj.label = JSON.parse( v.other )[ lang ];
			} catch ( error ) {
				obj.label = '';
			}
			return obj;
		});
	} else {
		return [];
	}
}

// 获取国家，国家码列表
async function $getCountry() {
	const res = await commonApi.getCountry();
	if ( res.status === 'success' ) {
		const countryList = res.result.map(( val ) => ({
			...val,
			value: val.id,
			label: val.name === val.native ? val.name : val.name + ' ' + val.native
		}));
		const phoneAreaList = [];
		res.result.forEach(( val ) => {
			if ( val.phone.split( ',' ).length === 1 ) {
				phoneAreaList.push({
					label: val.name === val.native ? `+${val.phone} ${val.name}` : `+${val.phone} ${val.name} ${val.native}`,
					value: `${val.phone}-${val.id}`
				});
			} else {
				val.phone.split( ',' ).map(( item ) => {
					phoneAreaList.push({
						label: val.name === val.native ? `+${item} ${val.name}` : `+${item} ${val.name} ${val.native}`,
						value: `${item}-${val.id}`
					});
				});
			}
		});
		store.commit( 'setPhone', [...phoneAreaList] || []);
		store.commit( 'setCountry', [...countryList] || []);
		return [...res.result];
	} else {
		return [];
	}
}

/**
 * 新窗口打开
 * @param {String} url 新窗口地址
 * @param {String} target 打开方式
 * @returns
 */
const $openUrl = ( url, target = '_blank' ) => {
	if ( !url ) {
		return;
	}
	const link = document.createElement( 'a' );
	link.target = target;
	link.style.display = 'none';
	link.href = url;
	document.body.appendChild( link );
	link.click();
	document.body.removeChild( link );
};

// 获取当前语言
function $lang() {
	const lang = VueCookieNext.getCookie( 'lang' );
	return lang;
}

// 语言转换
function $doLang( lang ) {
	if ( !lang || /^zh[-,,,_]{0,1}|^cn$/.test( lang )) {
		return 'zh-cn';
	}
	if ( /^en[-,,,_]{0,1}/.test( lang )) {
		return 'en-us';
	}
	if ( /^ja[-,,,_]{0,1}/.test( lang )) {
		return 'ja-jp';
	}
	return lang;
}

// lang转换
function $rdoLang( lang = $lang()) {
	if ( !lang || /^zh[-,,,_]{0,1}|^cn$/.test( lang )) {
		return 'cn';
	}
	if ( /^en[-,,,_]{0,1}/.test( lang )) {
		return 'en';
	}
	if ( /^ja[-,,,_]{0,1}/.test( lang )) {
		return 'ja';
	}
	return lang;
}

// 设置当前语言
function $setLang( val ) {
	if ( VueCookieNext.getCookie( 'user_id' )){
		commonApi.settingsLang({ userId: VueCookieNext.getCookie( 'user_id' ), lang: val }).then(() => {
			VueCookieNext.setCookie( 'lang', val, { expire: '3d', path: '/' });
			window.location.reload();
		});
	} else {
		VueCookieNext.setCookie( 'lang', val, { expire: '3d', path: '/' });
		window.location.reload();
	}
}

/**
 * 数据列表，请求下一页数据arrayB时，arrayB中的数据可能会和已展示的数据列表arrayA中有重复，从而去重并入到arrayA中
 * 前提：arrayB的数据和arrayA的数据，顺序没有变更。
 * @param {Array} arrayA 需要合并的数组
 * @param {Array} arrayB 需要被合并的数组
 * @param {String} key 每条数据的唯一识别key
 * @returns
 */
const $listDeduplication = ( arrayA, arrayB, key = 'id' ) => {
	const length1 = arrayA.length;
	const length2 = arrayB.length;
	let k = 0;
	for ( let i = 1;i <= Math.min( length1, length2 );i = i + 1 ) {
		if ( arrayA[ length1 - i ][ key ] === arrayB[ 0 ][ key ]) {
			k = i;
			break;
		}
	}
	if ( k === Math.min( length1, length2 ) + 1 ) {
		return arrayA.concat( arrayB );
	} else {
		console.log( arrayB.slice( k ));
		return arrayA.concat( arrayB.slice( k ));
	}
};
// 地址拼接
function $getCdnUrl( imgObj, config ) {
	let cdnUrl = '';
	if ( !imgObj || !imgObj.bucket ) {
		return cdnUrl;
	}
	// TODO 默认值修改
	const cdnUrlConfig = _get( store.state, 'global.cdnUrl', {});
	const cloud = _get( store.state, 'global.cloud', 'aliyun' );
	if ( config && config.width ) {
		const { width = 100, height = width } = config;
		if ( cloud === 'aliyun' ) {
			cdnUrl = cdnUrlConfig[ imgObj.bucket ] + imgObj.dfsID + `?x-oss-process=image/resize,w_${parseFloat( width )},h_${parseFloat( height )},m_fill`;
		} else if ( cloud === 'aws' ) {
			cdnUrl = cdnUrlConfig[ imgObj.bucket ] + `${width}xAUTO/` + imgObj.dfsID;
		}
	} else {
		cdnUrl = cdnUrlConfig[ imgObj.bucket ] + imgObj.dfsID;
	}
	return cdnUrl;
}

// msg报错处理
function $formErr( res, form, type = 'form' ) {
	if ( typeof res === 'object' && Object.keys( res ).length ) {
		for ( const key in form ) {
			form[ key ] = '';
		}
		nextTick(() => {
			Object.keys( res ).forEach(( val ) => {
				if ( type === 'form' ) {
					form[ val ] = res[ val ];
				} else {
					XlMessage({ type: 'error', message: res[ val ] });
				}
			});
		});
	} else {
		XlMessage({ type: 'error', message: res });
	}
}

// 退出登录
function $logout( redirectTo ) {
	if ( redirectTo !== 'header' ){
		$exitBySearch( redirectTo || router.resolve({ name: 'oemDomainPage', params: { oemDomain: VueCookieNext.getCookie( 'oemURL' ) || 'index' } }).href );
		return;
	}
	XlMessageBox({
		title: $t( 'logout.title' ),
		message: $t( 'logout.desc' ),
		cancelButtonText: $t( 'button.cancel' ),
		confirmButtonText: $t( 'button.confirm' )
	}).then(() => {
		!redirectTo && console.log( '$exitBySearch ： 退出登录' );
		// 登出时使后端token失效
		const userId = $getLocalStorage( 'userInfo' )?.userID;
		commonApi.userLogout({ logoutType: 'btsCloudSite', userId });
		$exitBySearch( redirectTo || router.resolve({ name: 'oemDomainPage', params: { oemDomain: VueCookieNext.getCookie( 'oemURL' ) || 'index' } }).href );
	}).
		catch(( e ) => {
			console.log( 'catch', e );
		});
}

const $checkPerm = ( perm ) => {
	const allperms = store.state.permsList || $getLocalStorage( 'perms' ) || [];
	return $hasOnePerm( allperms, perm );
};
const $hasOnePerm = ( perms, perm ) => {
	// 权限点不存在可显示
	if ( _isEmpty( perm )) {
		return true;
	}
	// 权限点存在但列表不存在则不显示
	if ( _isEmpty( perms )) {
		return false;
	}
	// 只要有一个权限点存在，则显示
	if ( Array.isArray( perm )) {
		for ( const k of perm ) {
			if ( perms.indexOf( k ) > -1 ) {
				return true;
			}
		}
	}
	// 单个权限点存在则显示
	if ( typeof perm === 'string' ) {
		if ( perms.indexOf( perm ) > -1 ) {
			return true;
		}
	}
	// 权限列表不为空，权限点不为空，权限点不存在于权限列表中
	return false;
};

function $privacyUpdate( status = true ) {
	const isLogin = window.location.pathname === '/' || window.location.pathname.startsWith( '/p/' );
	// 登录页不提示
	if ( isLogin && status ) {
		return;
	}
	const { agreedInfo } = $getLocalStorage( 'userInfo' );
	privacyUpdate({
		productKey: 'bts',
		agreedInfo,
		bgImg: $importImg( 'privacyUpdateBg.png' ),
		callback: ( flag ) => {
			if ( !flag ) {
				$exitBySearch();
			}
		}
	});
}

const $decodeHtml = ( html ) => {
	let s = html;
	const HTML_DECODE = {
		'&lt;': '<',
		'&gt;': '>',
		'&amp;': '&',
		'&nbsp;': ' ',
		'&quot;': '"',
		'&copy;': ''

		// Add more
	};
	const REGX_HTML_DECODE = /&\w+;|&#(\d+);/g;

	s = s !== undefined ? s : '';
	if ( s === '' ) {
		return s;
	}
	return typeof s !== 'string' ? s : s.replace(
		REGX_HTML_DECODE,
		function( $0, $1 ){
			let c = HTML_DECODE[ $0 ];
			if ( c === undefined ){
				// Maybe is Entity Number
				if ( !isNaN( $1 )){
					c = String.fromCharCode( $1 === 160 ? 32 : $1 );
				} else {
					c = $0;
				}
			}
			return c;
		}
	);
};

// 获取机构所属的节点列表
const $getNodeList = async () => {
	const res = await spaceApi.fetchFrontSpaceDistribution();
	if ( res.status === 'success' && Array.isArray( res.result )) {
		const nodes = res.result.map(( val ) => ({
			...val,
			value: val.matchNode,
			name: $t( 'nodes.' + val.matchNode )
		}));
		// 存储机构节点列表
		store.commit( 'setNodeList', [...nodes]);
		return [...nodes];
	} else {
		// 不存在直接清空节点列表
		store.commit( 'setNodeList', []);
		return [];
	}
};

export {
	$convertUTCDateToLocalDate,
	$convertLocalDateToUTCDate,
	$getInfo,
	$getCountry,
	$getPhoneArea,
	$doLang,
	$rdoLang,
	$lang,
	$setLang,
	$formErr,
	$setLocalStorage,
	$getLocalStorage,
	$removeLocalStorage,
	$checkPerm,
	$exitBySearch,
	$getCdnUrl,
	$logout,
	$openUrl,
	$getSettingList,
	$privacyUpdate,
	$decodeHtml,
	$importImg,
	$listDeduplication,
	$getNodeList
};
