import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';
import axios from 'axios';
import store from './store/index.js';
import { VueCookieNext } from 'vue-cookie-next';
// import './utils/vconsole.js';
// root主题色文件
import './assets/common/root.scss';
import './assets/font_cloud/iconfont';
import './assets/font_cloud/iconfont.css';
import '@shining3d/ui-components-theme/lib/css/elCommon.css';
import VueMatomo from '@shining3d/matomo';
import _get from 'lodash/get';
import directives from './directives/index.js';
const userId = VueCookieNext.getCookie( 'user_id' );
const app = createApp( App );

app.use( VueMatomo, {
	// 对应服务端埋点接口
	trackerUrl: _get( window, 'app.global.matomo.trackerUrl', '/api/matomoCollector?source=clouddata' ),
	// 埋点自动收集默认信息的脚本地址
	trackerScriptUrl: _get( window, 'app.global.matomo.trackerScriptUrl', 'https://t-pubhz.oss-accelerate.aliyuncs.com/sysParams/matomo-s.js' ),
	// //埋点平台项目的siteId, 2为测试通用
	siteId: _get( window, 'app.global.matomo.siteId', 17 ),
	// 是否通过埋点管理平台收录转发，若使用埋点管理平台则设置为true,默认false
	log: _get( window, 'app.global.matomo.log', true ),
	// 埋点管理平台对应的项目id。默认无，若log为true则必填
	projectID: _get( window, 'app.global.matomo.projectID', '628302ee-d30a-504d-bb3e-3829fd174537' ),
	// 自动注册Vue路由
	router: router,

	// 是否开启常规页面跳转追踪
	// 默认: true
	enableLinkTracking: true,

	// 再向matomo发送追踪信息前需要同意
	// 默认: false
	requireConsent: false,

	// 是否追踪初始化页面
	// 默认: true
	trackInitialView: true,

	// 是否关闭cookies运行matomo
	// 默认: false
	disableCookies: false,

	// 创建matomo服务前是否征得用户cookie授权
	// 默认: false
	requireCookieConsent: false,

	// 是否收集debug环境下的信息
	// 默认: false
	debug: false,

	// 传递给matomo的userId (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
	// 默认: undefined
	userId: userId || ''
});


// app.mixin( commonMixin );
app.use( store );
app.use( router );
app.use( directives );
app.mount( '#app' );


// 报错信息处理
const whiteErrList = [''];
app.config.errorHandler = ( err, instance, info ) => {
	console.log( err );
	if ( whiteErrList.every(( val ) => String( err ).indexOf( val ))){
		// 向追踪服务报告错误
		axios.post( '/log', { logs: err, instance, info });
	}
};

