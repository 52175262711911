import http from '@/utils/http.js';

const newRootUrl = '/api';

const Info = {
	/**
	 * 获取个人信息
	 * @returns
	 */
	getInfo() {
		return http.get( `${newRootUrl}/getUserProfile`, { errCode: 'info.getInfo' });
	},
	/**
	 * 发送验证码
	 * @param {*} data
	 * @returns
	 */
	userValidate( data ) {
		return http.post( `${newRootUrl}/userInfoValidateV2`, { data, errCode: 'info.userValidate' }, { headers: { 'X-Auth-Token': data.captcha } });
	},
	/**
	 * 获取云盘客服问题
	 * @returns
	 */
	fetchFAQ( params ) {
		return http.get( `${newRootUrl}/getProblem`, { params, errCode: 'info.fetchFAQ' });
	},
	/**
	 * 获取第三方sdk授权配置（微信）
	 * @returns
	 */
	fetchThirdPartyConfiguration( params ) {
		return http.get( `${newRootUrl}/appShare`, { params, errCode: 'info.fetchFAQ' });
	}
};
export default Info;
