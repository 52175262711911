/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = {
	spaceID: '', // 最后一次访问的空间
	spaceList: [], // 所有空间列表
	authList: [], // 权限角色列表
	capacity: {}, // 空间容量
	categoryList: [], // 模型类型
	triangularList: [] // 三角面片区间
};
const mutations = {
	updateSpaceID( state, id ) {
		state.spaceID = id;
	},
	updateSpaceList( state, list ) {
		state.spaceList = list.slice();
	},
	updateAuthList( state, list ) {
		state.authList = list.slice();
	},
	updateCapacity( state, capacity ) {
		state.capacity = { ...capacity };
	},
	updateCategoryList( state, categoryList ) {
		state.categoryList = categoryList.slice();
	},
	updateTriangularList( state, triangularList ) {
		state.triangularList = triangularList.slice();
	}
};
const getters = {
	permissions( state ){
		const spaceID = state.spaceID || '';
		const role = state.spaceList.find(( s ) => s.spaceID === spaceID )?.role;
		return state.authList.find(( a ) => a.uniqueName === role )?.uniCode || [];
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations
};
