import $store from '@/store/index';

export default {
	mounted( el, binding ) {
		const permissions = $store.getters[ 'space/permissions' ];
		if ( binding.value ) {
			const hasPer = permissions.some(( item ) => binding.value.includes( item ));
			if ( !hasPer ) {
				el.parentNode && el.parentNode.removeChild( el );
			}
		}
	}
};
